<template>
  <div class="news">
    <head-nav></head-nav>
    <about-news-img></about-news-img>

    <div class="news-box">
      <h3 class="news-box-title">
        <span>
          <i
            class="iconfont icon-zuobianjiantou"
            @click="onPlusYearClick"
          ></i>
        </span>
        <span class="new-box-time">
          {{currentYear}}
        </span>
        <span>
          <i
            class="iconfont icon-youbianjiantou"
            @click="onMinusYearClick"
          ></i>
        </span>
      </h3>

      <div class="news-box-info">
        <div class="news-box-info-list">
          <div
            class="news-box-info-lists"
            v-for="(item, index) in list"
            :key="index"
            @click="tolink(item.id)"
          >
            <div class="news-box-info-lists-left">
              <img
                :src="item.image.publicUrl"
                alt=""
              >
            </div>
            <div class="news-box-info-lists-right">
              <h4 class="news-box-info-lists-right-title">
                {{item.title}}
              </h4>
              <p class="news-box-info-lists-right-content">
                {{item.abstract}}
              </p>
            </div>
          </div>

        </div>

        <div class="news-list-all">
          <button
            class="news-list-all-btn"
            @click="allpage()"
          >
            {{$t('about.moreBtn')}}
          </button>
        </div>
      </div>
    </div>
    <footers></footers>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import HeadNav from './head-nav';
import AboutNewsImg from './about-news-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutNewsImg,
    Footers,
  },
  apollo: {
    yearRange: {
      // 请求数据
      query: gql`
        query {
          # 请求第一页的数据
          miniYear: allPosts(orderBy: "createdAt_ASC", first: 1) {
            createdAt
          }
          maxYear: allPosts(orderBy: "createdAt_DESC", first: 1) {
            createdAt
          }
        }
      `,
      update(value) {
        return {
          miniYear: value.miniYear[0].createdAt,
          maxYear: value.maxYear[0].createdAt,
        };
      },
    },
    postResponse: {
      query: gql`
        query($miniYear: DateTime!, $maxYear: DateTime!, $skip: Int!, $first: Int!) {
          # 请求参数和类型
          allPosts(
            where: { createdAt_gte: $miniYear, createdAt_lte: $maxYear }
            skip: $skip
            first: $first
            sortBy: createdAt_DESC
          ) {
            id
            title
            abstract
            image {
              publicUrl
            }
          }
          _allPostsMeta(where: { createdAt_gte: $miniYear, createdAt_lte: $maxYear }) {
            count
          }
        }
      `,
      variables() {
        const { page, pageSize } = this.pagination;
        return {
          miniYear: this.queryRange.min,
          maxYear: this.queryRange.max,
          skip: (page - 1) * pageSize,
          first: pageSize,
          // 计算第一页页脚和最后一页页脚
        };
      },
      skip() {
        return !this.currentYear;
      },
      update(value) {
        const localPostList = this.postResponse.allPosts;
        if (localPostList) {
          return {
            ...value,
            allPosts: [...localPostList, ...value.allPosts],
          };
        }
        return value;
      },
    },
  },
  data() {
    return {
      currentYear: 0,
      // 初始化的年份
      yearRange: {
        miniYear: '',
        // 最小的年份
        maxYear: '',
        // 最大的年份
      },
      postResponse: {
        allPosts: null,
        // 请求的postResponse里面的allPosts
        total: 0,
      },
      pagination: {
        page: 1,
        // 页脚第一页
        pageSize: 5,
        // 一页显示几条数据
      },
    };
  },
  watch: {
    maxYear() {
      this.currentYear = this.maxYear;
      // 监听当前最大的年份
    },
    currentYear() {
      this.pagination = {
        page: 1,
        pageSize: 5,
        // 一页显示几条数据
      };
    },
  },
  computed: {
    list() {
      if (this.postResponse.allPosts) {
        return this.postResponse.allPosts;
      }
      return [];
      // 创建一个新数组
    },
    total() {
      // eslint-disable-next-line no-underscore-dangle
      const total = this.postResponse?._allPostsMeta?.count;
      if (total) {
        return total;
      }
      return 0;
      // 判断当前页脚
    },
    pageCount() {
      return Math.ceil(this.total / this.pagination.pageSize);
      // 计算当前的分页按钮
    },
    miniYear() {
      if (this.yearRange.miniYear) {
        return dayjs(this.yearRange.miniYear).year();
      }
      return '';
      // 判断最小的年份
    },
    maxYear() {
      if (this.yearRange.maxYear) {
        return dayjs(this.yearRange.maxYear).year();
      }
      return '';
      // 判断最大的年份
    },
    queryRange() {
      const { currentYear } = this;
      if (currentYear) {
        const currentYearDate = dayjs(currentYear.toString(), 'YYYY');
        return {
          min: currentYearDate.startOf('year').toISOString(),
          max: currentYearDate.endOf('year').toISOString(),
        };
      }
      return null;
    },
    // 处理年份的日期
  },
  methods: {
    tolink(id) {
      this.$router.push({
        name: 'about-news-details',
        params: {
          id,
        },
      });
    },
    allpage() {
      if (this.pagination.page < this.pageCount) {
        this.pagination.page += 1;
      } else if (this.pagination.page === this.pageCount) {
        this.$notify({
          type: 'success',
          title: '已经到底部了',
        });
      }
    },
    onPlusYearClick() {
      this.postResponse.allPosts = [];
      if (this.currentYear < this.maxYear) {
        this.currentYear += 1;
      }
      // 加年份
    },
    onMinusYearClick() {
      this.postResponse.allPosts = [];
      if (this.currentYear > this.miniYear) {
        this.currentYear -= 1;
      }
      // 减年份
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-notification {
  padding: 10px;
  margin: 0 5px 5px;

  font-size: 12px;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.success {
    background: red;
    border-left-color: red;
  }
}
.news {
  background: #f8f8f8;
  .news-box {
    margin: 0 30px;

    &-title {
      padding: 50px 0;
      text-align: center;
      font-size: 72px;
      line-height: 72px;
      font-weight: 100;

      &-time {
        margin: 0 30px;
      }

      span {
        i {
          font-size: 72px;
          font-weight: 100;
        }
      }
    }

    .news-box-info {
      &-list {
        .news-box-info-lists {
          width: 100%;
          height: 180px;
          display: flex;
          flex: 1;
          justify-content: space-around;
          border: 1px solid #eee;
          border-radius: 10px;
          overflow: hidden;
          margin-bottom: 20px;

          &-left {
            img {
              width: 250px;
              height: 100%;
            }
          }

          &-right {
            width: 450px;
            padding: 10px 20px;

            &-title {
              width: 100%;
              color: #555;
              font-size: 28px;
              margin-top: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }

            &-content {
              margin-top: 10px;
              line-height: 40px;
              font-size: 24px;
              color: #999;
              display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
              -webkit-box-orient: vertical; /*值必须为vertical*/
              -webkit-line-clamp: 2; /*值为数字，表示一共显示几行*/
              overflow: hidden;
            }
          }
        }
      }

      .news-list-all {
        margin: 50px 0 100px 0;
        text-align: center;

        &-btn {
          width: 400px;
          text-align: center;
          height: 70px;
          line-height: 70px;
          border: none;
          border-radius: 40px;
          background: #ffbf00;
          color: #555;
          outline: none;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .news .news-box .news-box-info-list .news-box-info-lists-left {
    width: 450px;
  }
  .news .news-box .news-box-info-list .news-box-info-lists-right {
    width: 350px;
  }
}
</style>
